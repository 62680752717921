import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
// import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer'
import Header from '@/components/header/header';
import Breadcrumb from '@/components/breadcrumb/breadcrumb';
import Toolbar from '@/components/toolbar/toolbar';
import {product,apiGet} from '../../services/api'

export default {
    name: "Product",
    data(){
        return{
            product_list: null,
            chk3:false,
            h_ele:[],
        }
    },
    created () {
        window.scrollTo(0,0);
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener("resize", this.onResize);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        gotoDetail(vals){
            // this.$router.push({ name: 'product-detail' })
            if(vals.link_name){
                this.$router.push('solar') 
            }else{
                this.$router.push('product-detail/'+vals.id) 
            }
        },
        handleScroll (evt) {
            // Any code to be executed when the window is scrolled
            // console.log(evt)
            // console.log(window.scrollY)
        },
        onResize(evt){
            if(window.innerWidth<800) $('.footer').css({'position':'relative','top':'0px'});

            let h1_helgiht = $('.important3-BG .year-BG>h1')[0].offsetHeight
            let set_scroll_helgiht = $('#year-BG')[0].offsetHeight;
            console.log("set_scroll_helgiht",set_scroll_helgiht,"all_helgiht",h1_helgiht,h1_helgiht*2.5)
            $('.control-year').css({'height':h1_helgiht*2+'px'})
            // $('.important3-BG').css({'height':300*13+'px'})
            $('.important3-BG').css({'height':(((h1_helgiht+6)*19)+1000)+'px'})
        },
        setSticky(){

            let lastScrollTop = 0;
            let chkIdx = 0
            let lastScrollMoveIdx = 0
            $(".chk-scroll").scroll(function(event) {
                // console.log(">>3",$(".chk-scroll").scrollTop())
    
                // --------------
                // $('.txt-BG>div').hide()
                // --------------
    
                let pos_title = $("#focusThis").offset().top
                // window.scrollTo(pos_title,0);
                window.scrollTo(0,pos_title);
                let move = $(".chk-scroll").scrollTop()
                let trans = `translateY(-${move}px)`;
                let h_h1 = $('.important3-BG .year-BG>h1')[0].offsetHeight;
                h_h1 = h_h1- (h_h1*2/100)
                let pos = Math.floor(move/h_h1)
                // console.log(`Move :> ${move}`,"pos",pos,chkIdx)
                // console.log("Height",$('.important3-BG .year-BG>h1')[0].offsetHeight,", move",move)
                // $('div').css({"-webkit-transform":"translate(100px,100px)"});​
                // $('#year-BG').css({"-webkit-transform":`translateY(-${move})`});​
                if(pos>=0&&pos<18){
                    $('.important3-BG .year-BG').css({'-webkit-transform': trans})
                }
                var st = $(this).scrollTop();
                if(chkIdx!=pos && pos>=0&&pos<19){
                    $(`.txt-BG >div`).hide()
                    // setTimeout(() => {
                        if (st > lastScrollTop){
                            // downscroll code
                            if(pos>=0&&pos<19){
                                $(`.txt-BG > .active,#year-BG > h1`).removeClass('active')
                                $(`#con-${pos+1}`).addClass('active')
                                // $(`#con-${pos}`).fadeOut(1000)
                                $(`#con-${pos+1}`).fadeIn(1000)
                                $(`#year-BG > h1.y${pos+1}`).addClass('active')
                                // $(`#con-${pos}`).hide(1000)
                            }
                        } else {
                            // upscroll code
                            // console.log("UP",pos)
                            $(`.txt-BG > .active,#year-BG > h1`).removeClass('active')
                            $(`#con-${pos + 1}`).addClass('active')
                            $(`#year-BG > h1.y${pos + 1}`).addClass('active')
                            $(`#con-${pos + 1}`).fadeIn(1000)
                        }
                    // }, 1000);
                }
                
                if(pos>18){
                    // console.log(`LAST----${lastScrollMoveIdx}---${move}-----`)
                    // console.log($(".chk-scroll"))
                    // console.log($(".chk-scroll")[0].offsetHeight)
                    // console.log($('.important3-BG')[0].offsetHeight)
                    if(!lastScrollMoveIdx) lastScrollMoveIdx= move
                    if (st > lastScrollTop){
                        $(".chk-scroll").scrollTop(9999)
                        // $('.important3-BG').css({'height':move+'px'})
                        // downscroll code
                    } else {
                        $(".chk-scroll").scrollTop(lastScrollMoveIdx)
                        // upscroll code
                    }
                }
                chkIdx = pos
                lastScrollTop = st;
            });
            $(".important2-BG").scroll(function(event) {
                location.href='#focusThis'
                $(".test-scroll").css( "display", "inline" ).fadeOut( "slow" );
                // console.log(">>",event)
                // console.log(">>1",$(".important2-BG").position().top)
                // console.log(this.h_ele)
                // console.log(">>2",$(".important2-BG").offset().top)
                // console.log(">>3",$(".important2-BG").scrollTop())
                // console.log("each-1",$("#tt-1").position())
                // console.log("each-2",$("#tt-2").position())
                // console.log("each-3",$("#tt-3").position())
                
            });
            let h1_helgiht = $('.important3-BG .year-BG>h1')[0].offsetHeight
            let set_scroll_helgiht = $('#year-BG')[0].offsetHeight;
            console.log("set_scroll_helgiht",set_scroll_helgiht,"all_helgiht",h1_helgiht,h1_helgiht*2.5)
            $('.control-year').css({'height':h1_helgiht*2+'px'})
            // $('.important3-BG').css({'height':300*13+'px'})
            $('.important3-BG').css({'height':(((h1_helgiht+6)*19)+1000)+'px'})
        }
    },
    async mounted () {
        document.title = "AGC - Product";
        // AOS.init();
        // resize();
        // function resize(){
        //     if(window.innerWidth<800) $('.footer').css({'position':'relative','top':'0px'});
        // }
        // this.product_list = await apiGet(product,{
        //     page : 1,
        //     limit : 100000000,
        //     sort : 'ASC',
        // })
        // if($( window ).width()>1200){
        //     if((this.product_list.length/3)% 1 != 0) this.chk3 = true
        // }
        this.setSticky()
    },
    components: {
        Footer,Header,Breadcrumb,Toolbar
    }
};