export default {
    name: "toolbar",
    methods: {
        changeLocale(locale) {
          this.$i18n.locale = locale
          localStorage.setItem('locale', locale)
          location.reload()
        }
      }
};
